/**
 * Main function 
 */
(function() {
    /* --- Konami Code --- */
    const easterEgg = new Konami(function() {
        // Enabled konami code easter egg
        umami.track('konami-code');
        const answer = document.querySelectorAll('.answer');
        for(let i = 0; i < answer.length; i++) {
            answer[i].classList.remove("hide");
        }
    });
})();
